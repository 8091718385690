<template>
  <div class="account-content">
    <div>
      <div class="disal">
        <div class="mr10 all-amount">
          {{ translateTitle("你的总余额预估") }}
        </div>
        <!-- <el-select class="svg-container" style="width: 120px"  v-if="this.$route.path !='/accountManage'"
          v-model="choiceCoin.coinName"
          :placeholder="translateTitle('请选择')"
          @change="changeType"
        >
          <template #prefix>
            <span class="flex">
              <img
                :src="choiceCoin.coinLogo"
                width="24"
                height="24"
              />
            </span>
          </template>
          <el-option
            v-for="item in option"
            :key="item.coinName"
            :label="item.coinName"
            :value="item.coinName"
          >
            <div class="disal">
              <img
                :src="item.coinLogo"
                width="24"
                height="24"
              />
              <span class="select-item">{{ item.coinName }}</span>
            </div>
          </el-option>
        </el-select> -->
      </div>
      <div class="all-num"> 
	 <!-- <img
          width="25px"
          src="@/assets/imgs/newImg/USDT.png"
          alt=""
        /> -->
		<p style="font-size: 32px;
font-weight: bold;">{{ bankBalance }}</p>
		<span>USD</span>
		</div>
      <!-- <div class="account-num">
        {{ translateTitle('银行账户资产') }}：<span class="mr10"> {{bankBalance}} USDT</span>
        {{ translateTitle('加密货币账号') }}：<span>{{UsdtBalance}} USDT</span>
      </div> -->
    </div>
    <!-- <div class="account-center">
      <div class="">
        <div class="mr10 title">{{ translateTitle("银行余额") }}</div>
        <div class="amount">{{ bankBalance }}&nbsp;USDT</div>
      </div>
      <div class="">
        <div class="mr10 mt20 title">
          {{ translateTitle("加密货币余额") }}
        </div>
        <div class="amount">{{ UsdtBalance }}&nbsp;USDT</div>
      </div>
    </div> -->
    <!-- <div style="width: 120px" v-if="this.$route.path =='/accountManage'">
      <el-select 
          class="svg-container"
          v-model="choiceCoin.coinName"
          :placeholder="translateTitle('请选择')"
          @change="changeType"
        >
        <template #prefix>
            <span class="flex">
              <img
                :src="choiceCoin.coinLogo"
                width="24"
                height="24"
              />
            </span>
          </template>
          <el-option
            v-for="item in option"
            :key="item.coinName"
            :label="item.coinName"
            :value="item.coinName"
          >
            <div class="disal">
              <img
                :src="item.coinLogo"
                width="24"
                height="24"
              />
              <span class="select-item">{{ item.coinName }}</span>
            </div>
          </el-option>
        </el-select>
    </div> -->
    <div class="btn" @click="transfer">
      {{ translateTitle("充值加密账号") }}&nbsp;&nbsp;<i class="el-icon-right"></i>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  coinNames,
  accountTotal,
  userBankBalance,
  totalUsdtBalance,
  coinBalanceUSD,
  coinLink,
  getTransferRate,
} from "@/api/index";
import Headers from "../../components/accountHeader/index.vue";
import { coin_transfer, bank_transfer,bank_card_transfer } from "@/api/accountManage";
import { translateTitle } from "@/utils/i18n";
import {multiply,subtr,} from '@/utils/date'

import local from "@/utils/local";
export default {
  components: { Headers },
  data() {
    return {
      value1: false,
      choiceCoin: {
        coinName: '',
        coinLogo: "",
      },
      total: 20,
      size: 10,
      option: [],
      BalanceCoinNam: "0",
      BalanceCoinNam1: "0",
      pageSize: 10,
      page: 1,
      visible: false,
      btnloading: false,
      isCheck: "1",
      ruleForm: {
        email: "",
        amount: "",
        coinName: "",
        coinLink: "",
        bankCardNo: "",
      },
      checked: false,
      BalanceCoinNam: "",
      UsdtBalance: "0.00",
      bankBalance: "0.00",
      CoinLinkOption: [],
      transferRate: '',
      transferAmount: '',
    };
  },
  watch: {
    'ruleForm.amount': function (params) {
      if (params == '') { this.transferAmount = 0; } else { this.transferAmount = this.multiply(this.transferRate,params); }
    },
  },
  created() {
    // this.getTableData();
    this.getBankBalance();
  },
  methods: {
    subtr,
    multiply,
    translateTitle,
    ...mapActions({
      changeCoinName: "settings/changeCoinName",
    }),
    async getCoinLink(val) {
      let res = await coinLink(val);
      this.CoinLinkOption = res.data;
    },
    transfer() {
      // this.visible = true;
      // let params = {
      //   coinLink: 'USD',
      //   coinName: 'USD',
      //   type: '0'
      // }
      // getTransferRate(params).then(res => {
      //   this.transferRate = res.data.rate
      // })
      // this.$router.push('/transferMoney/detail')
      // this.getCoinLink(this.ruleForm.coinName);
	  this.$emit('recharge','recharge')
    },
    getBankBalance(val) {
      this.getUserBankBalance();
      // this.getTotalUsdtBalance();
    },
    getUserBankBalance() {
      userBankBalance().then((res) => {
        if(res.code == 200) {
          this.bankBalance = res.data.amount;
        } else {
          this.$message.error(this.translateTitle(res.msg));
        }
      });
    },
    // getTotalUsdtBalance() {
    //   totalUsdtBalance().then((res) => {
    //     if(res.code == 200) {
    //       this.UsdtBalance = res.data.amount;
    //     } else {
    //       this.$message.error(this.translateTitle(res.msg));
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.account-content {
  width: 100%;
  background-image: url(../../assets/imgs/newImg/cardBg.png);
  border-radius: 16px;
  padding: 30px 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  .all-amount {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .all-num {
    font-size: 32px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: 700;
    color: #FFFFFF;
	    display: flex;
	    align-items: baseline;
    margin: 15px 0px 0px 0px;
    img {
      margin-right: 5px;
    }
    span {
      font-weight: 700;
      color: #FFFFFF;
      font-size: 14px;
      margin-left: 5px;
    }
  }
  .account-num {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255,255,255,0.8);
  }
  .btn {
    width: 20%;
	cursor: pointer;
    height: 60px;
    background: #FFFFFF;
    border-radius: 12px;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
    font-weight: 400;
    color: #31363E;
  }
  .account-center {
    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #8da0ad;
    }
    .amount {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: bold;
      color: #31363e;
      padding-top: 5px;
    }
  }
}
::v-deep {
  .custum-dialog {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .img {
      text-align: center;
      background: #f2f7f9;
      border-radius: 10px;
      // padding: 30px 30px 20px 30px;
      padding: 20px 0;
      width: 46%;
      border: 2px solid #f2f7f9;
      p {
        font-size: 22px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: bold;
        color: #24282e;
      }
      // img {
      //   width: 60%;
      // }
    }
    .img.active {
      border: 2px solid #f6d655;
    }
  }
}
.select-item {
  margin-left: 10px;
}
</style>
